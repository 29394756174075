<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div
      style="
        font-size: 16px;
        display: flex;
        place-content: center flex-start;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 25px;
        margin-bottom: 20px;
        margin-top: 10px;
      "
    >
      <div style="display: flex; margin-bottom: 15px">
        <el-form :model="SerachInput" label-width="80px">
          <div style="display: flex; flex-wrap: wrap; align-items: center">
            <el-form-item label="创建时间">
              <el-col>
                <el-date-picker
                  v-model="overallForm.time"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="商品分类">
              <el-col :span="8">
                <el-select
                  v-model="SerachInput.product"
                  placeholder="请选择"
                  @change="shop"
                  clearable
                >
                  <el-option
                    v-for="item in optionssex"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col
                class="line"
                :span="1"
                style="margin-left: 5px; margin-right: 5px"
              >
                --</el-col
              >
              <el-col :span="8">
                <el-select
                  v-model="SerachInput.goods"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in optionssexs"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="订单号">
              <el-input
                v-model="SerachInput.order"
                placeholder="请输入订单号"
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="用户手机">
              <el-input
                v-model="SerachInput.phone"
                placeholder="请输入手机号"
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="IMEI" label-width="60px">
              <el-input
                v-model="SerachInput.imei"
                placeholder="请输入IMEI号"
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="">
              <img
                src="../../assets/images/query.png"
                alt=""
                @click="imaClick"
              />
            </el-form-item> -->
          </div>
        </el-form>
      </div>
      <div style="margin-bottom: 5px">
        <div style="margin-bottom: 2%">
          <span style="color: #909399; font-size: 14px; font-weight: bold"
            >支付方式：</span
          >
          <el-radio v-model="radio" label="">全部</el-radio>
          <el-radio v-model="radio" label="1">公众号</el-radio>
          <el-radio v-model="radio" label="2">微信App</el-radio>
          <el-radio v-model="radio" label="3">支付宝</el-radio>
          <el-radio v-model="radio" label="4">苹果支付</el-radio>
        </div>
        <div>
          <span style="color: #909399; font-size: 14px; font-weight: bold"
            >订单状态：</span
          >
          <el-radio v-model="radios" label="">全部</el-radio>
          <el-radio v-model="radios" label="1">未支付</el-radio>
          <el-radio v-model="radios" label="2">支付成功</el-radio>
          <el-radio v-model="radios" label="3">支付失败</el-radio>
        </div>
      </div>
      <div>
        <img
          src="../../assets/images/query.png"
          alt=""
          @click="imaClick"
          style="width: 49px; margin-top: 48%"
        />
      </div>
    </div>
    <div class="table-content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        ref="multipleTable"
        style="width: 100%"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column
          prop="orderNo"
          label="订单号"
          width="200px"
        ></el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          width="170px"
        ></el-table-column>
        <el-table-column
          prop="updateDate"
          label="更新时间"
          width="170px"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="用户手机"
          width="120px"
        ></el-table-column>
        <el-table-column
          prop="imei"
          label="IMEI"
          width="150px"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="用户昵称"
          width="150px"
        ></el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品名"
          width="110px"
        ></el-table-column>
        <el-table-column prop="monthNum" label="有效期"></el-table-column>
        <el-table-column prop="tradeType" label="支付方式"></el-table-column>
        <el-table-column prop="totalFee" label="金额"></el-table-column>
        <el-table-column prop="state" label="订单状态"></el-table-column>
        <el-table-column prop="finish" label="发货状态"></el-table-column>
        <el-table-column label="操作" width="150px">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >订单处理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom">
      <div class="bottom-left">
        <p class="bottom-shu">
          查询订单总数<span style="margin-left: 10px">{{ count }}</span>
        </p>
        <p class="bottom-shu">
          查询订单金额<span style="margin-left: 10px">{{ price }}</span>
        </p>
      </div>
      <div class="bottom-right">
        <div class="bottom-fen">
          <!-- 分页 -->
          <div class="pages">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :current-page="pageCount"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
            <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
          </div>
        </div>
        <div class="buttons">
          <el-button @click="exportList">数据导出</el-button>
        </div>
      </div>
    </div>
    <!-- 订单处理的弹框 -->
    <div>
      <el-dialog
        title="订单处理"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <el-radio
          v-model="radioAll"
          label="1"
          style="margin-left: 41%; margin-bottom: 7%"
          >强制发货</el-radio
        >
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="quit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      radioAll: "1",
      clientHeight: document.body.clientHeight,
      SerachInput: {},
      overallForm: {
        time: [],
      },
      optionssex: [
        {
          name: "全部分类",
          value: null,
        },
        {
          name: "医疗设备",
          value: 1,
        },
        {
          name: "安防设备",
          value: 2,
        },
        {
          name: "适老设备",
          value: 3,
        },
        {
          name: "智能机器人",
          value: 4,
        },
        {
          name: "智能呼叫器",
          value: 5,
        },
        {
          name: "其他",
          value: 6,
        },
      ],
      optionssexs: [],
      radio: "",
      radios: "",
      tableData: [],
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      dialogVisible: false,
      count: null,
      price: null,
      shopType: "",
      userNum: null,
      orderNum: "",
    };
  },
  created() {
    this.getList();
    this.getSer();
    let aa = JSON.parse(localStorage.getItem("loginuser"));
    this.userNum = aa.id;
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    quit() {
      this.$confirm("确定强制发货?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // console.log('传入：',this.orderNum,this.userNum);
          this.dialogVisible = false;
          apiShout
            .getopSer({
              orderNo: this.orderNum,
              userId: this.userNum,
            })
            .then((res) => {
              console.log("强发货：", res.data);
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "发货成功!",
                });
                this.getList();
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    shop(val) {
      console.log("val:", val);
      if (val == null || val == 6) {
        api.getSelectProduct({ dictCode: "openservicetype" }).then((res) => {
          console.log("全部服务", res.data);
          if (res.data.code == 200) {
            this.optionssexs = res.data.result;
          }
        });
      } else {
        this.shopType = val;
        this.getSer();
      }
    },
    getSer() {
      apiShout.getserPay({ type: this.shopType }).then((res) => {
        // console.log('商品分类后边:',res.data);
        if (res.data.code == 200) {
          this.optionssexs = res.data.result;
        }
      });
    },
    getList() {
      apiShout
        .getshopList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          orderNo: this.SerachInput.order,
          phone: this.SerachInput.phone,
          product: this.SerachInput.product,
          goods: this.SerachInput.goods,
          state: this.radios,
          tradeType: this.radio,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.count = res.data.result.count;
            this.price = res.data.result.price;
            this.tableData.forEach((ele) => {
              console.log(ele);
              ele.totalFee = Number(ele.totalFee) / 100 + "元";
              ele.monthNum = ele.monthNum + "个月";
              if (ele.finish == "1") {
                ele.finish = "已发货";
              } else {
                ele.finish = "未发货";
              }
            });
            this.price = Number(res.data.result.price) / 100 + "元";
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    imaClick() {
      apiShout
        .getshopList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          orderNo: this.SerachInput.order,
          phone: this.SerachInput.phone,
          product: this.SerachInput.product,
          goods: this.SerachInput.goods,
          state: this.radios,
          tradeType: this.radio,
          imei:this.SerachInput.imei,
        })
        .then((res) => {
          console.log("搜索：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.count = res.data.result.count;
            this.price = res.data.result.price;
            this.tableData.forEach((ele) => {
              console.log(ele);
              ele.totalFee = Number(ele.totalFee) / 100 + "元";
              ele.monthNum = ele.monthNum + "个月";
              if (ele.finish == "1") {
                ele.finish = "已发货";
              } else {
                ele.finish = "未发货";
              }
            });
            this.price = Number(res.data.result.price) / 100 + "元";
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/order/exportShopOrder",
          params: {
            startTime:
              this.overallForm.time == null ? "" : this.overallForm.time[0],
            endTime:
              this.overallForm.time == null ? "" : this.overallForm.time[1],
            orderNo: this.SerachInput.order,
            phone: this.SerachInput.phone,
            product: this.SerachInput.product,
            goods: this.SerachInput.goods,
            state: this.radios,
            tradeType: this.radio,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "付费管理" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    handleEdit(index, row) {
      console.log("898989:", index, row);
      this.orderNum = row.orderNo;
      this.dialogVisible = true;
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getList();
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-form-item__label {
  color: #909399;
  text-align: left;
}
/deep/.el-form-item {
  margin-bottom: 10px;
  margin-right: 40px;
}
/deep/.el-col-1 {
  width: 5.2%;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
  padding: 0 10px;
}
.table-content {
  margin-left: 25px;
  margin-right: 40px;
}
.bottom-right {
  float: right;
  margin-right: 30px;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
  display: flex;
}
.bottom-shu {
  font-size: 15px;
  margin-left: 3%;
  margin-top: 15px;
  color: #606266;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    margin-right: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
// table 表格所有的格式
.tables {
  .el-table::before {
    height: 0;
  }
  .el-table {
    td,
    th.is-leaf {
      border: none !important;
    }
    tr th {
      font-size: 25px;
      color: #706d6d;
      text-align: center;
    }
    tr td {
      text-align: center;
      font-size: 25px;
      color: #a8a8a8;
    }
  }
}
/deep/.el-table .cell {
  color: #606266;
  font-size: 14px;
}
/deep/ .el-table .el-table__cell {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
/deep/ .el-table th.el-table__cell > .cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 14px;
  color: #909399;
  font-weight: bold;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-dialog__footer {
  padding: 10px 20px 20px;
  text-align: center;
  box-sizing: border-box;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
// /deep/.el-button {
//   padding: 7px 13px;
//   background-color: #d78673;
//   color: #fff;
//   border-color: #d78673;
// }
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>